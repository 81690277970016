.container {
  border: #273238 0.5px solid;
  margin: 0;
  padding-bottom: 2px;
}

.blockTitle {
  margin: 0px;
  padding: 3px 5px;
  background: #273238;
  border-radius: 5px 5px 0 0;
  color: #fff;
  border-bottom: solid 1px rgba(0, 0, 0, 0.8);
}

.boxPrincipal {
  padding: 5px;
}

.checkBoxSemanal {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  margin: 10;
  margin-bottom: 10px;
  padding-right: 10px;
  padding-left: 10px;
}

.sorteioEhora {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 10px;
  padding-left: 10px;
}

.sorteioEhora2 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
}

.inputNome {
  min-width: 200px;
}

@media (max-width: 500px) {
  .inputNome {
    width: 100%;
  }

  .selectStyle {
    width: 100%;
  }
  .sorteioEhora2 {
    width: 100%;
  }
}

.checkBoxItem {
  flex-grow: 1;
}

.selectStyle {
  min-width: 150px;
  max-width: 150px;
}

.funkLabel {
  min-width: 140px;
}

.funkyradioInfo input[type="radio"]:checked ~ label:before,
.funkyradioInfo input[type="checkbox"]:checked ~ label:before {
  color: #fff;
  background-color: #5bc0de;
}

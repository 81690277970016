.container {
  margin-top: 10px !important;
  border: #273238 0.5px solid;
  margin: 0;
  border-radius: 5px;
  padding-bottom: 2px;
}

.display {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.inputFiltro {
  margin: 5px 0;
  width: 49%;
  min-width: 200px;
}

.btnCriar{
  height: 38px;
}
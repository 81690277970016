.seninha{
    border:1px solid black;
    margin-bottom: 30px;
}

.header_seninha{
    height: 44px;
}

.containerModal{
	align-items: center;
	justify-content: center;
}

.titleModal{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.titleModal h5{
    font-weight: bold;
}

.field{
    display: flex;
}

.field input{
    margin-left: 10px;
    width: 150px;
}

.days {
    margin-top: 15px;
}

.days label{
 margin-left: 10px;
}

.labelModal{
    margin-left: 10px;  
}


.containerModal .buttonClose{
	float:right;
	margin-top: 40px;
}

.containerModal .buttonClose button{
    padding: 0 15px;
    margin: 0 5px;
}






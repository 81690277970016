.containerBoxes * {
  font-family: "Inter", sans-serif;
}

.containerBoxes {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
}

.containerBoxes section {
  flex-basis: 30%;
}

.containerBoxes section:last-child {
  visibility: hidden;
}

.containerBoxes section h1 {
  text-align: center;
  font-size: 21px;
  
  margin-bottom: 20px;
  margin-top: 20px;
}

/*RESUMO DO CAIXA*/
.boxCalculations {
  display: flex;
  flex-direction: column;

  border: #ccc 1px solid;
  margin-bottom: 20px;
}

.boxCalculations div {
  display: flex;
  align-items: center;
  text-align: center;
}

.boxCalculations div {
  border-bottom: #cccccc 1px solid;
}

.boxCalculations div p:first-of-type {
  display: flex;
  flex-direction: column;
  flex-basis: 75%;

  border-right: #cccccc 1px solid;
  padding: 5px;
}

.boxCalculations div p:last-of-type {
  flex-basis: 25%;
}

.boxCalculations button {
  padding: 5px;
  background-color: #EFEFEF;
}

.boxCalculations button:hover {
  background-color: #E5E5E5;
}

.boxCalculations div p,
.boxCalculations button {
  font-size: 16px;
  color: #000000;
}

/*FORMULÁRIO DE PORCENTAGEM DO PREMIO*/
.formPremiumPercentage div {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  
  margin-bottom: 20px;
}

.formPremiumPercentage div label {
  flex: 2;
}

.formPremiumPercentage div input {
  flex: 1;
}

.formPremiumPercentage button {
  float: right;
  margin-bottom: 20px;
}
h1,
p {
  font-family: Lato;
}
.tablePagination {
  width: 100%;
  border-collapse: collapse;
  position: relative;
}

.tablePagination thead tr th {
  font-weight: bold;
  background-color: #fafafa;
}

.tablePagination tbody tr:nth-child(even) {
  background-color: #fafafa;
}

th,
td {
  padding: 8px;
  overflow-wrap: break-word;
}

.pagination-bar {
  width: 100%;
  display: flex;
  justify-content: center;
}

.conteudoPrincipal {
	padding: 10px;
	border: 1px solid rgba(0, 0, 0, 0.8);
}

.conteudoPrincipal .formGroup{
	display: flex;
	flex-direction: row;
	padding: 10px;
}

.conteudoPrincipal .fields_form{
	display: flex;
	flex-direction: row;
	width: 100%;
}

.conteudoPrincipal .fields_form .inputs{
	width: 50%;
}

.conteudoPrincipal .fields_form .img{
	width: 50%;
	text-align: center;
	padding: 20px;
}

.conteudoPrincipal .formGroup .label{
	width: 50%;
}

.conteudoPrincipal .formGroup .selectField{
    width: 50%;
}


.conteudoPrincipal .textSorteio{
	width: 50%;
}

.conteudoPrincipal .buttonStyle{
	padding: 25px 0px 25px 0px;
	margin-left: 50%;
	display: flex;
}

.conteudoPrincipal .buttonStyle button{
	margin-left: 20%;
}


.conteudoPrincipal .fields_form .img{
	font-size: 40px;	
}

.conteudoPrincipal .description{
	width: 50%;
}

.conteudoPrincipal .description span{
	font-size: 20px;
}






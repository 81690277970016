@import url(./variables/colors.css);
@import url(./variables/typography.css);
@import url(./variables/space.css);
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');

* {
    font-family: "Inter", sans-serif;
}

#menu li a:focus {
    color: #fdbb30;
    text-decoration: none !important;
}

.btn-link,
.btn-link:hover {
    color: #202121;
}

.app-content {
    padding: 9px 10px;
    margin-top: 3px;
    background-color: var(--background);
    border-color: #f7f7ff;
    width: 1000px;
}

label {
    margin-bottom: 0;
    font-weight: bold;
}

.badge {
    border-radius: 0 !important;
}

.titulo-principal {
    margin: 0;
    background: #222;
    color: #fff;
    padding: 10px;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
}

.subtitulo {
    margin: 0;
    padding: 10px;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
}

.titulo-principal > *:first-child {
    flex-grow: 1;
}

.conteudo-principal {
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.8);
}

.d-flex {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.d-flex.duas-colunas > * {
    width: 50%;
    margin-right: 10px;
}

.d-flex.tres-colunas > * {
    width: 33%;
    margin-right: 10px;
}

.d-flex.quatro-colunas > * {
    width: 25%;
    margin-right: 10px;
}

.d-flex > *:last-child {
    margin-right: 0;
}

.input-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

input:not([type="checkbox"]),
select,
input:not([type="checkbox"]):focus,
select:focus {
    padding: 6px !important;
    height: 38px;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    box-shadow: none !important;
    min-width: 75px;
    border-radius: 0 !important;
}

textarea,
textarea:focus {
    padding: 6px !important;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    box-shadow: none !important;
    border-radius: 0 !important;
}

.btn {
    height: 35px;
    line-height: 15px;
    font-weight: 700;
    border-radius: 0;
}

.btn-light {
    border-color: #bbb;
}

.btn-danger {
    background-color: #c00;
}

.btn-danger:hover {
    background-color: #b30000;
}

.btn-success {
    background-color: #1ab51a;
}

.btn-success:hover {
    background-color: #179f17;
}

.table-responsive {
    border: 1px solid rgba(0, 0, 0, 0.8);
    width: 100%;
}

.table {
    text-align: center;
    margin-bottom: 0;
}

.table th.titulo {
    background-color: #444;
    padding: 8px 0 !important;
}

.table td,
.table th {
    border: 1px solid #dee2e6;
    border-top: none;
    vertical-align: middle;
}

.table td:first-child,
.table th:first-child {
    border-left: none;
}

.table > thead > tr > th,
.table > tbody > tr > td {
    padding: 0 5px;
    /*white-space: nowrap;*/
}

.table > tbody > tr > td {
    border-color: rgba(0, 0, 0, 0.1);
}

.table > thead > tr:first-child > th {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
}

.table > thead > tr > th {
    color: #000;
    background-color: #c6c6cc;
    padding: 0 5px;
    border-color: rgba(0, 0, 0, 0.1);
}

.table > tfoot > tr:last-child > th {
    padding: 10px 0;
    color: #000;
    background-color: #c6c6cc;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.table > tfoot > tr:first-child > th {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.table tbody tr:nth-of-type(odd) {
    background-color: #fff;
}

.table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.table .btn {
    padding: 9px;
    height: 30px;
    line-height: 12px;
    font-size: 0.9em;
}

.text-success {
    color: #1ab51a;
}

.text-danger {
    color: red;
}

.text-blue {
    color: #00c;
}

.text-black {
    color: #000 !important;
}

.m-t {
    margin-top: 12px;
}

.m-b {
    margin-bottom: 12px;
}

.table tr td:last-child,
.table tr th:last-child {
    border-right: none !important;
}

.table tr:last-child td,
.table tr:last-child th {
    border-bottom: none !important;
}

input[aria-autocomplete="list"],
input[aria-autocomplete="list"]:focus {
    height: 2px !important;
    padding: 0px !important;
    opacity: 0 !important;
}

.page-container {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
}

.sidebar-menu {
    min-width: 185px;
    background-color: #202121 !important;
    color: #aaabae;
}

.left-content {
    /* width: auto; */
    margin-bottom: 15px;
}

td {
    padding: 5px !important;
}

.logo-sideBar {
    margin: 10% 0 -15% 15%;
}

.logo-sideBar img {
    width: 120px;
}

.btn-prestacao {
    padding: 10px;
    display: flex;
    align-items: center;
    width: 115px;
    height: 32px;
    border-radius: 0;
    background-color: #1ab51a;
    color: #fff;
}

.btn-menu-prestacao {
    height: 38.5px;
    font-weight: bold;
    border-radius: 0;
}

input,
.btn {
    padding: 9px 12px;
}

.page-container {
    height: 100%;
    margin: 0px auto;
    display: flex;
    min-height: 100vh;
}

.left-content {
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
}

#menu {
    list-style: none;
    margin: 0;
    padding: 0;
}

#menu li a {
    display: block;
    padding: 10px;
    color: #ffffff;
}

#menu li a:hover,
#menu li a.active {
    color: #fdbb30;
}

#menu .fa {
    font-size: 1rem;
}

#menu .fa::before {
    width: 20px;
    margin-right: 8px;
    display: inline-block;
    text-align: center;
}

#menu .fa span {
    font-family: "Inter", sans-serif !important;
}

.menu {
    padding: 4.2em 0em 0em 0em;
}

.logo {
    width: 22%;
    box-sizing: border-box;
    position: absolute;
    top: 10px;
    left: 10px;
}

.header-main {
    width: 100%;
    background: #fff;
    padding: 0.2em 1em;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
}

.header-right {
    float: right;
    width: 50%;
}

.header-left {
    float: left;
    width: 45%;
}

.logo-name {
    float: left;
    width: 100%;
    margin: 0% 3% 0% 2%;
}

.buttonHeaderLogo {
    visibility: hidden;
}

::-webkit-input-placeholder {
    color: #7a7b78 !important;
}

.profile_details {
    float: right;
}

.profile_img {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
}

.profile_details ul li {
    list-style-type: none;
    position: relative;
}

.user-name p {
    font-size: 1em;
    color: #fc8213;
    line-height: 1em;
    font-weight: 700;
}

.profile_details ul {
    padding: 0px;
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

.true {
    color: green;
}

.false {
    color: red;
}

.input-criacao-usuario {
    padding-top: 20px;
    min-width: 300px !important;
    max-width: 800px;
    display: flex;
}

.input-dados-criar {
    flex-basis: 270px !important;
    /* flex-grow: 1; */
}

.titulo-input-criacao {
    flex-basis: 30% !important;
    max-width: 150px;
}

.titulo-input-criacao-senha {
    flex-basis: 30% !important;
    max-width: 200px;
}

.caixa-inputs {
    margin-top: 6px;
    margin-left: 10px;
    display: flex;
    padding-right: 2px;
    flex-direction: column;
}

.margin-vertical-30 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.bordered {
    border: 1px solid rgba(0, 0, 0, 0.8);
}

.bordered2 {
    border: 1px solid rgba(0, 0, 0, 0.8);
    border-top: none;
}

.caixaAdmin {
    background-color: var(--background);
    min-width: 270px;
    text-align: center;
    padding: 8px 5px;
    font-size: 19px;
    color: #000;
}

@media (max-width: 800px) {
    .buttonHeaderLogo {
        visibility: visible;
        padding: 0px;
        margin: 0px;
        left: 10px;
    }
}

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

a {
    font-size: 14px;
    line-height: 1.7;
    color: #666666;
    margin: 0px;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
}

a:focus {
    outline: none !important;
}

a:hover {
    text-decoration: none;
    color: #333333;
}

h2,
h3 {
    margin: 0px;
}

p {
    font-size: 14px;
    line-height: 1.7;
    color: #666666;
    margin: 0px;
}

ul,
li {
    margin: 0px;
    list-style-type: none;
}

input {
    outline: none;
    border: none;
}

textarea {
    outline: none;
    border: none;
}

textarea:focus,
input:focus {
    border-color: transparent !important;
}

input:focus::-webkit-input-placeholder {
    color: transparent;
}

input:focus:-moz-placeholder {
    color: transparent;
}

input:focus::-moz-placeholder {
    color: transparent;
}

input:focus:-ms-input-placeholder {
    color: transparent;
}

textarea:focus::-webkit-input-placeholder {
    color: transparent;
}

textarea:focus:-moz-placeholder {
    color: transparent;
}

textarea:focus::-moz-placeholder {
    color: transparent;
}

textarea:focus:-ms-input-placeholder {
    color: transparent;
}

input::-webkit-input-placeholder {
    color: #adadad;
}

input:-moz-placeholder {
    color: #adadad;
}

input::-moz-placeholder {
    color: #adadad;
}

input:-ms-input-placeholder {
    color: #adadad;
}

textarea::-webkit-input-placeholder {
    color: #adadad;
}

textarea:-moz-placeholder {
    color: #adadad;
}

textarea::-moz-placeholder {
    color: #adadad;
}

textarea:-ms-input-placeholder {
    color: #adadad;
}

button {
    outline: none !important;
    border: none;
    background: transparent;
}

button:hover {
    cursor: pointer;
}

.limiter {
    width: 100%;
    margin: 0 auto;
}

.container-login100 {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background: #fff;
}

.wrap-login100 {
    width: 330px;
    background: #fff;
}

.login100-form-title {
    display: block;
    font-size: 30px;
    color: #333333;
    line-height: 1.2;
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
}

.wrap-input100 {
    width: 100%;
    position: relative;
    border-bottom: 2px solid #d9d9d9;
}

.input100 {
    font-size: 18px;
    color: #555555;
    line-height: 1.2;

    display: block;
    width: 100%;
    height: 80px;

    background: transparent;
    padding: 0 5px;
}

.container-login100-form-btn {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.login100-form-btn {
    font-size: 16px;
    color: #fff;
    line-height: 1.2;
    text-transform: uppercase;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 50px;
    background-color: #57b846;
    font-weight: bold;
}

.validate-input {
    position: relative;
}

.funkyradio2 input[type="radio"]:hover:not(:checked) ~ label,
.funkyradio2 input[type="checkbox"]:hover:not(:checked) ~ label {
    color: #888;
}

.funkyradio2 input[type="radio"]:hover:not(:checked) ~ label:before,
.funkyradio2 input[type="checkbox"]:hover:not(:checked) ~ label:before {
    content: "\2714";
    text-indent: 0.4em;
    color: #c2c2c2;
}

body {
    font-size: 16px !important;
    background: var(--background);
    font-family: "Inter", sans-serif;
}

.loader {
    display: inline-block;
    width: 80px;
    height: 80px;
}

.loader:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fc8213;
    border-color: #fc8213 transparent #fc8213 transparent;
    animation: loader 1.2s linear infinite;
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.linkButton {
    font-size: 16px;
    font-weight: 700;
    color: var(--link);
    text-decoration: underline;
}

.borderTable {
    border: 1px solid rgba(0, 0, 0, 0.8);
    width: 100% !important;
}

.displayNone {
    display: none !important;
}

.center {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.modal-bilhete {
    width: 300px !important;
    margin-left: 20%;
}

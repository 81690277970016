:root {
	--background: #fff;
	--text: #666666;
	--title: #2e384d;
	--link: #3da1ff;
	--pago: #007bff;
	--pendente: #6d757d;
	--perdedor: #dc3545;
	--cancelado: #ffc107;
	--vencedor: #28a745;
}

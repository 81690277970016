.containerTablesGerente {
	width: fit-content;
	font-size: 14px;
}

.containerTablesGerente .fontBold{
	font-weight: bold !important;
}

.containerTablesGerente td,
tr:nth-of-type(2) th {
	border: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.containerTablesGerente table + table {
	margin-top: 25px;
}

.containerTablesGerente tbody :is(th, td),
.containerTablesLancamento tbody :is(th, td) {
	background-color: #fff;
	padding: 5px 4px !important;
}

.containerTablesGerenteHead th,
.summaryTableGerente th {
	padding: 6px 4px !important;
}

.containerTablesGerenteBody td {
	width: 100px;
}

.containerTablesGerenteBody td:is(:nth-of-type(1)) {
	width: 200px;
}
.containerTablesGerenteBody td:is(:nth-of-type(2)) {
	width: 70px;
}

.containerTablesGerenteBody
	td:is(:nth-of-type(2), :nth-of-type(3), :nth-of-type(4), :nth-of-type(7)),
.summaryTableGerente
	th:is(:nth-of-type(2), :nth-of-type(3), :nth-of-type(4), :nth-of-type(7)),
.summaryBox
	tbody
	th:is(:nth-of-type(2), :nth-of-type(3), :nth-of-type(4), :nth-of-type(7)) {
	color: black;
}

.summaryTableGerente th {
	background-color: #fff;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.footerTableGerente th div {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.footerTableGerente th {
	background-color: #cccccc !important;
}

.footerTableGerente th p {
	flex-basis: 50%;
	color: #000;
	font-weight: 700;
	line-height: 24px;
}

.containerTablesLancamento {
	font-size: 14px;
}

.containerTablesLancamentoHead th {
	padding: 6px 4px !important;
}

.footerTableGerente th,
.containerTablesGerenteHeadPrimary th {
	padding: 7px !important;
}

.summaryBox th,
.summaryBox tbody th {
	padding: 7px !important;
}

.footerTableGerente div {
	display: flex;
	justify-content: space-between;
}

.borderColor{
	border:1px solid #ccc;
}

.borderBetWeenTable{
	height: 20px;
}

.salvarPDF{
	margin-top:20px;
	height:38px;
	background-color: #EFEFEF;
	color: black;
	border:1px solid #767676;
	font-weight: bold;
   
  }
  
.salvarPDF:hover{
	background: #E5E5E5;
	border:1px solid #4F4F4F;
	color:black;
}
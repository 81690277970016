
.saldoContainer {
  display: flex;
}

.saldoContainer .iconLeft {
  display: flex;
  justify-content: center;
  width: 60px;
  height: 30px;
  background-color: #C71616;
  margin: 10px 4px 4px 4px;
}

.saldoContainer .iconLeft i {
  color: white;
}

.saldoContainer .input {
  display: flex;
  justify-content: center;
  border-radius: 7px;
  margin: 4px;
}

.saldoContainer .iconRight {
  display: flex;
  justify-content: center;
  width: 60px;
  height: 30px;
  background-color: #1ab51a;
  margin: 10px 4px 4px 4px;
}

.saldoContainer .iconRight i {
  color: white;
}
.content {
	display: flex;
	justify-content: center;
}
.bilheteContainer {
	max-width: 88mm;
	width: 100%;
	padding: 2mm 2mm 10mm 2mm;
	margin: 0 auto;
	background-color: #f6ecc7;

	color: #000;
	font-family: Arial, sans-serif;
	font-size: 16px;
	font-weight: 400;
}

.bilheteContainer,
h3,
h4 {
	margin: 0;
}

.bilheteContainer h3 {
	font-size: 20px;
	font-weight: bold;
}

.bilheteContainer h4 {
	font-size: 18px;
}

.bilheteContainer hr {
	border-top: 1px solid #000;
	margin: 10px 0px;
}

.bilheteContainer .logoDiv {
	display: flex;
	justify-content: center;
	margin: 15px;
}

.bilheteContainer .logoDiv img {
	width: 110px;
}

.badge {
	border-radius: 5px;
	padding: 3px;
	color: #fff;
	font-size: 12px;
	/* font-weight: 400; */
}

.box {
	width: 100%;
	height: 50px;
	margin-top: 20px;
	color: #fff;
	font-size: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.numero {
  display: inline-block;
  /*padding: 15px;*/
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.pago {
	background: var(--pago);
}
.pendente {
	background: var(--pendente);
}
.perdedor {
	background: var(--perdedor);
}
.cancelado {
	background: var(--cancelado);
}
.vencedor {
	background: var(--vencedor);
}

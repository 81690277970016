.containerModal{
	align-items: center;
	justify-content: center;
}


.containerModal .premio{
	display: flex;
	justify-content: center;
	margin-top:20px;
	font-size: 40px;
}

.containerModal .premio img{
	border-radius: 100px;
}

.containerModal .ganhador{
	display: flex;
	justify-content: center;
	font-size:20px;
	padding-top: 10px;
}

.containerModal .ganhador p{
	padding-right: 10px;
	font-size: 1.2rem;
}

.containerModal .info{
	margin-top: 20px;
}

.containerModal .info p{
	padding-right: 10px;
}

.containerModal .info .text_info{
	display: flex;
	margin-bottom: 3px;
}

.containerModal .info .text_info strong{
	color: #000;
    font-family: Arial,sans-serif;
    font-size: 16px;
}

.containerModal .info .text_info p{
	margin-top:0px;
	margin-left:5px;
	font-size: 18px;
	line-height: 1.3  !important;
}

.containerModal .buttonClose{
	float:right;
	margin-top: 40px;
 }


.noResult{
	text-align: center;
	padding: 30px;
  }
  
.noResult p{
	font-size: 20px;
  
}

.header_sorteio{
	display: flex;
	justify-content: right;
}

.table_sorteio{
	margin-top: 12px;
	border:1px solid black;
}




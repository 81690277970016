.containerHeader {
    display: flex;
    gap: 10px;
    padding: 10px;
}

.apostas {
    flex-basis: 15%;
    border: #D3D3D3 1px solid;
    padding: 5px;
}

.premio {
    flex-basis: 20%;
    border: #D3D3D3 1px solid;
    padding: 5px;
}

.pontos {
    flex-basis: 65%;
    border: #D3D3D3 1px solid;
}

.apostas,
.premio {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.apostas p,
.premio p {
    font-size: 1.25rem;
    font-weight: bold;
    color: #000;
    font-family: "Inter", sans-serif;
}

.apostas strong,
.premio strong {
    font-size: 1.5rem;
    font-weight: bold;
    color: #000;
    font-family: "Inter", sans-serif;
}

.pontos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
}

.pontos > p {
    font-size: 1rem;
    font-weight: bold;
    color: #000;
    font-family: "Inter", sans-serif;

    text-align: center;

    border-bottom: #D3D3D3 1px solid;

    padding: 5px;
}

.pontos > div {
    display: flex;
    justify-content: space-between;
}

.pontos_item {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    flex: 1;
}

.pontos_item + .pontos_item {
    border-left: #d3d3d3 1px solid;
}

.pontos_item p {
    font-size: 1rem;
    color: #000;
    font-family: "Inter", sans-serif;

    text-align: center;
    border-bottom: #d3d3d3 1px solid;

    padding: 5px 0;
}

.pontos_item strong {
    font-size: 1rem;
    color: #000;
    font-family: "Inter", sans-serif;

    text-align: center;

    padding: 5px 0;
}


/*Resultados*/
.containerResultado > p {
    background: #d3d3d3;

    font-size: 1.125rem;
    /*font-weight: bold;*/
    color: #000;
    font-family: "Inter", sans-serif;

    padding: 10px;
}

.containerResultado > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;

    padding: 10px;
}

.extracao {
    flex-basis: 30%;

    display: flex;
    align-items: center;
    gap: 10px;
}

.extracao p {
    font-size: 1rem;
    font-weight: bold;
    color: #000;
    font-family: "Inter", sans-serif;
}

.resultados {
    flex-basis: 70%;

    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 10px;
}

.resultados p {
    flex: 1;

    font-size: 1rem;
    color: #000;
    font-family: "Inter", sans-serif;

    text-align: center;

    padding: 8px;
    border: #d3d3d3 1px solid;
}

/*Bilhetes*/
.containerBilhete td{
    padding: 0 !important;
}

.containerBilhete ul{
    display: flex;
}

.containerBilhete li{
    flex: 1;
    padding: 10px 4px;

    color: #fff;
}

.containerBilhete li + li{
    border-left: #d3d3d3 1px solid;
}

/*Modal*/
.content {
    display: flex;
    justify-content: center;
}
.bilheteContainer {
    max-width: 88mm;
    width: 100%;
    padding: 2mm 2mm 10mm 2mm;
    margin: 0 auto;
    background-color: #f6ecc7;

    color: #000;
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
}

.bilheteContainer,
h3,
h4 {
    margin: 0;
}

.bilheteContainer h3 {
    font-size: 20px;
    font-weight: bold;
}

.bilheteContainer h4 {
    font-size: 18px;
}

.bilheteContainer hr {
    border-top: 1px solid #000;
    margin: 10px 0px;
}

.bilheteContainer .logoDiv {
    display: flex;
    justify-content: center;
    margin: 15px;
}

.bilheteContainer .logoDiv img {
    width: 110px;
}

.badge {
    border-radius: 5px;
    padding: 3px;
    color: #fff;
    font-size: 12px;
    /* font-weight: 400; */
}

.box {
    width: 100%;
    height: 50px;
    margin-top: 20px;
    color: #fff;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.numero {
    display: inline-block;
    /*padding: 15px;*/
    width: 25px;
    height: 25px;
    border-radius: 50%;
}

.pago {
    background: var(--pago);
}
.pendente {
    background: var(--pendente);
}
.perdedor {
    background: var(--perdedor);
}
.cancelado {
    background: var(--cancelado);
}
.vencedor {
    background: var(--vencedor);
}

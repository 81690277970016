.buttonSalvarPDF{

  height:38px;
  background-color: #EFEFEF;
  color: black;
  border:1px solid #767676;
  font-weight: bold;
 
}

.buttonSalvarPDF:hover{
  background: #E5E5E5;
  border:1px solid #4F4F4F;
}


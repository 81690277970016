  //Completely customizable
  //--
  $font-size: 35px;
  $letter-spacing: 0.0625em; //This counts for 1px on 16px font;
  $letter-count: 11; //Number of letters in text !important
  $transition-time: 1s; //Typing speed
  $bg-color: #eff5ed;
  $mask-color: #ffffff; //Change it to see how this works :)
  $font-color: #131313;
  
  $letter-size: calc(1ch + #{$letter-spacing});
  $cursor-width: 1px; //Change this to $letter-size if you want Insert:active effect
  //--
  $cursor-blink-duration: $letter-count;
  $font-width: calc(#{$letter-count}ch + #{$letter-count}*#{$letter-spacing});
  
  *, *:after, *:before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  .hello {
  
    h3 {
      position: relative;
        color: $font-color;
        text-align: center;
        white-space: nowrap;
      font-size: $font-size;
      letter-spacing: $letter-spacing;
      // width: $font-width;
  
        &:after {
        display: block;
        position: absolute;
        content: '';
        width: $font-width;
        height: 2.3ex;
        left: 0;
        top: 0;
        background: $mask-color;
        animation: .6s blink $cursor-blink-duration, move $transition-time 1.5s forwards steps(1);
        border-left: $cursor-width solid $font-color;
        // box-shadow: 0px 1ex 0px 0px $mask-color;
  
        @keyframes move {
          @for $i from 1 through $letter-count {
            $temp: 100/$letter-count;
            $percent: $i*$temp;
            #{$percent}% {
              width: calc(#{$font-width} - (#{$i}ch + #{$i}*#{$letter-spacing}));
              left: calc(#{$i}ch + #{$i}*#{$letter-spacing});
            }
          }
        }
  
        @keyframes blink {
          0% {
              border-left-color: $font-color;
          }
          50% {
              border-left-color: $font-color;
          }
          51% {
              border-left-color: $mask-color;
          }
          100% {
              border-left-color: $mask-color;
          }
        }
        }
    }
  }
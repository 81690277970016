.conteudoPrincipal {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.8);
}

.conteudoPrincipal .formGroup {
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.conteudoPrincipal .formGroup .label {
  width: 30%;
}

.conteudoPrincipal .formGroup .field {
  width: 70%;
}

.conteudoPrincipal .formGroup .field .gerente {
  border: 1px solid black;
  height: 250px;
}

.conteudoPrincipal .formGroup .field .gerente {
  border: 1px solid black;
  height: 150px;
}

.conteudoPrincipal .buttonStyle {
  padding: 25px 0px 25px 0px;
  margin-left: 50%;
}

.conteudoPrincipal .buttonStyle button {
  margin-left: 45%;
}

.inputLogin, .inputLogin:focus{
    height: 50px !important;
}


.descarga{
  display: flex;
  flex-direction: column;

  border: 1px solid #000000;
  margin-top: 13px;
}

.titlesecondTable{
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    height: 24px;
    background-color: #666666;
}

.titlesecondTable > span{
  margin-left: 10px;
}


.descargaLista{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 13px;

  padding: 13px;
}

.descargaButton{
  border-top: 1px solid #000000;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  gap: 13px;

  padding: 9px 13px;
}

.descargaButton > button{
  flex: 1;
}

.noResult{
  text-align: center;
  padding: 30px;
}

.noResult p{
  font-size: 20px;

}


.buttonSalvarPDF{
  height:38px;
  background-color: #EFEFEF;
  color: black;
  border:1px solid #767676;
  font-weight: bold;
 
}

.buttonSalvarPDF:hover{
  background: #E5E5E5;
  border:1px solid #4F4F4F;
}

.containerTitle{
  margin-top:15px;
  border:1px solid black;
}

.title{
  margin-left: 10px;
}

.spinner.is-animating {
  animation: loading 4s linear infinite;
}

.pointer {
  width: 10px;
  height: 10px;
  position: relative;
  top: 120px;
  left: 2px;
  border-radius: 100%;
  z-index: 1;
  border: 1px solid #b17447;
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
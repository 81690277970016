.pagination {
	display: flex;
	justify-content: center;
	align-content: center;
	margin: 20px;
}

.pagination a {
	color: var(--text);
	float: left;
	padding: 8px 16px;
	text-decoration: none;
	transition: background-color 0.3s;
	border: 1px solid #ddd;
	margin: 0 4px;
	cursor: pointer;
}

.pagination a.active {
	background-color: var(--background);
	color: var(--link);
	border: 1px solid var(--link);
}

.pagination a:hover:not(.active) {
	background-color: var(--background);
	color: var(--link);
	border: 1px solid var(--link);
}
.pagination a:hover(.active) {
	color: var(--link) !important;
}

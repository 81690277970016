.conteudoPrincipal {
	padding: 10px;
	border: 1px solid rgba(0, 0, 0, 0.8);
}

.conteudoPrincipal .formGroup{
	display: flex;
	flex-direction: row;
	padding: 10px;
}

.conteudoPrincipal .formGroup .label{
	width: 50%;
}

.conteudoPrincipal .formGroup .input{
    width: 50%;
}


.conteudoPrincipal .buttonStyle{
	padding: 10px 0px 25px 0px;
	margin-left: 50%;
}

.conteudoPrincipal .buttonStyle button{
	width: 300px;
}



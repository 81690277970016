.container {
	justify-content: left !important;
	min-width: 900px;
}
.tableCaixa{
	width: 100% !important;
	height:max-content;
	border:1px solid black;
}

